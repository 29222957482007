import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
// const Success = React.lazy(() => import('./views/pages/success'))
// const Home = React.lazy(() => import('./views/pages/home'))
// const Terms = React.lazy(() => import('./views/pages/terms'))
// const Privacy = React.lazy(() => import('./views/pages/privacy'))
// const Reset = React.lazy(() => import('./views/pages/Reset/forgotpass'))
const CareerGrowth = React.lazy(() => import('./views/CareerGrowth/CareerGrowth'))
// const Dashboard = React.lazy(() => import('./views/PrivateDashboard/privateDashboard'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<CareerGrowth />} />
            {/* <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/success" name="Success" element={<Success />} />
            <Route exact path="/Dashboard" name="Success" element={<Dashboard />} />
            <Route exact path="/termsandcondition" name="Terms and condition" element={<Terms />} />
            <Route exact path="/privacy-policy" name="Privacy Policy" element={<Privacy />} />
            <Route exact path="/reset" name="Privacy Policy" element={<Reset />} /> */}

            <Route
              exact
              path="/career-growth"
              name="Career Growth & Placement"
              element={<CareerGrowth />}
            />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
